import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private router = inject(Router);
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  public apiService: ApiService = inject(ApiService);
  private storageService = inject(StorageService);
  constructor() { }

  public login(user: {email: string, password: string}) : Observable<any> {
    return this.apiService.login(user).pipe(
      tap((tokens: any) => this.doLoginUser(user.email, JSON.stringify(tokens))),
    )
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem(this.JWT_TOKEN) && !this.isRefreshTokenExpired();
  }

  private doLoginUser(email: string, tokens: any) {
    localStorage.setItem('user', email);
    this.storeJwtToken(tokens);
    // TODO: duplicate
    if (tokens) {
      const token: any = JSON.parse(tokens).accessToken;
      const decoded: any = jwtDecode(token);
      this.storageService.isAdmin = decoded.admin;
      localStorage.setItem('user_id', decoded.sub);
    }
    this.isAuthenticatedSubject.next(true);
    this.storageService.$authEvent.next(true);
  }

  private storeJwtToken(jwt: any) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/login'])
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  isAccessTokenExpired() {
    const tokens = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return true;
    const token = JSON.parse(tokens).accessToken;
    const decoded = jwtDecode(token);
    if (!decoded.exp) return true;
    const expirationDate = decoded.exp * 1000;
    const now = new Date().getTime();

    return expirationDate < now;
  }

  isRefreshTokenExpired() {
    const tokens = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return true;
    try {
      const token = JSON.parse(tokens).refreshToken;
      const decoded = jwtDecode(token);
      if (!decoded.exp) return true;
      const expirationDate = decoded.exp * 1000;
      const now = new Date().getTime();

      return expirationDate < now;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  refreshStaffToken() {
    let tokens: any = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return;
    tokens = JSON.parse(tokens);
    const refreshToken = tokens.refreshToken;
    // const accessToken = tokens.accessToken;

    if (this.isRefreshTokenExpired()) {
      localStorage.clear();
      sessionStorage.clear();
      this.isAuthenticatedSubject.next(false);
      this.router.navigate(['/login'])
      window.location.reload();

      return new BehaviorSubject<any>(null);
    }

    return this.apiService.refreshStaffToken({
      headers: {
        'x-user-id': localStorage.getItem('user_id') || '',
        'x-token': refreshToken
      }
    }).pipe(tap((tokens: any) => this.storeJwtToken(JSON.stringify(tokens))));
  }

  refreshToken() {
    let tokens: any = localStorage.getItem(this.JWT_TOKEN);
    if (!tokens) return;
    tokens = JSON.parse(tokens);
    const refreshToken = tokens.refreshToken;
    const accessToken = tokens.accessToken;
    return this.apiService.refreshClientToken({
      refreshToken,
      email: localStorage.getItem('user')
    }).pipe(tap((tokens: any) => this.storeJwtToken(JSON.stringify(tokens))));
  }
}
